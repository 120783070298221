import axios from "axios";
export const initStepFinal = () => {
    const stepFinalWrapper = window.configurator.root.querySelector('.productConfiguratorModal__step[data-step="3"]');

    if(!stepFinalWrapper) return;

    const result = stepFinalWrapper.querySelector('.productConfiguratorModal__result');
    const loader = stepFinalWrapper.querySelector('.productConfiguratorModal__calculating');

    window.configurator.root.addEventListener('update_view', () => {
        if(window.configurator.current_step === 3){
            let footer = stepFinalWrapper.querySelector('.productConfiguratorModal__footer');
            footer.style.display = "none";

            const hiddens = window.configurator.root.querySelectorAll('input[type=hidden]');

            const formData = [];
            hiddens.forEach(hidden => {
                formData.push({
                   key: hidden.name,
                   value: hidden.value
                });
            });

            axios.post(`/wp-json/product-configurator/v1/summary`, {
                data: formData,
                product_id: window.configurator.product_id,
                nonce: ajax.nonce
            }).then(res => {
                result.innerHTML = res.data;
                loader.style.display = "none";
                result.style.display = "block";
                footer.style.display = "flex";
            });
        } else{
            loader.style.display = "block";
            result.style.display = "none";
        }
    });

    const prepareAddToCart = () => {
        const addToCart = window.configurator.root.querySelector('.complete_add_to_cart');

        if(!addToCart) return;

        addToCart.addEventListener('click', () => {
            addToCart.classList.add('adding');
            const hiddens = window.configurator.root.querySelectorAll('input[type=hidden]');
            let footer = stepFinalWrapper.querySelector('.productConfiguratorModal__footer');

            const data = {
                'product_id': window.configurator.product_id,
                'variation_id': jQuery('input.variation_id').val(),
                'nonce': ajax.nonce,
                'data': []
            };
            hiddens.forEach(hidden => {
                data['data'].push({
                    key: hidden.name,
                    value: hidden.value
                });
            });

            axios.post(`${ajax.url}?action=pc_add_to_cart`, {
                data
            }).then(res => {
                if (res.data.error) {
                    window.configurator.add_to_cart_status = 'failed';
                } else {
                    jQuery(document.body).trigger('added_to_cart', [res.data.fragments, res.data.cart_hash, jQuery('.single_add_to_cart_button')]);
                    addToCart.classList.remove('adding');
                    window.configurator.add_to_cart_status = 'success';
                }
                window.configurator.current_step++;
                window.configurator.update();
            });
        })

        window.configurator.root.addEventListener('go-back', () => {
            if(window.configurator.current_step === 3){
                addToCart.classList.remove('adding');
                result.innerHTML = "";
            }
        })
    }

    prepareAddToCart();
}