import './scss/app.scss';
import {initProductConfigurator} from "./js/app";
import {initCollapses} from "./js/collapses";
import Correction_lens from "./js/correction_lens";

if(document.querySelector('.customProductPage')){
    initProductConfigurator();
}

// Correction_lens();

if(document.querySelector('.productConfigurationCollapse')){
    initCollapses();

    jQuery( document.body ).on( 'updated_wc_div', () => {
        initCollapses();
    });
}