export const initThankYou = () => {
    const thankYouWrappers = window.configurator.root.querySelectorAll('.productConfiguratorModal__thankYou');

    if(!thankYouWrappers) return;

    window.configurator.root.addEventListener('update_view', () => {
        if(window.configurator.current_step === 4){
            thankYouWrappers.forEach(thankYouWrapper => {
                thankYouWrapper.classList.remove('active');

                if(thankYouWrapper.getAttribute('data-type') === window.configurator.add_to_cart_status){
                    thankYouWrapper.classList.add('active');
                }
            })
        }
    });
}