import {initProductConfiguratorModal} from "./modal";
import {initProductConfiguratorOpener} from "./opener";
import {initStep1} from "./step_1";
import {initStep2} from "./step_2";
import {initStepFinal} from "./step_final";
import {initFormCollectorData} from "./form";
import {initThankYou} from "./thankyou";

export const initProductConfigurator = () => {
    window.configurator = {
        product_id: document.querySelector('form.cart button[type=submit]').value,
        current_step: 1,
        add_to_cart_status: 'failed',
        root: document.querySelector('#productConfiguratorModal'),
        trigger: (event) => {
            let e = new Event(event);
            window.configurator.root.dispatchEvent(e);
        },
        update: () => {
            window.configurator.trigger('update_view');
        }
    };

    if(!window.configurator.root) return;

    const variationForm = document.querySelector('form.variations_form');

    if(variationForm){
        window.configurator.product_id = variationForm.getAttribute('data-product_id');

        jQuery('.single_variation_wrap').on('show_variation', function (event, variation) {
            window.configurator.product_id = variation.variation_id;
            console.log(window.configurator)
        })
    }

    initFormCollectorData();
    initProductConfiguratorModal();
    initProductConfiguratorOpener();
    initStep1();
    initStep2();
    initStepFinal();
    initThankYou();
}