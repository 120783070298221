import Splide from '@splidejs/splide';

export const initStep2 = () => {
    const step2Wrapper = window.configurator.root.querySelector('.productConfiguratorModal__step[data-step="2"]');

    if(!step2Wrapper) return;

    document.addEventListener( 'DOMContentLoaded', function() {
        const lensSliderElems = step2Wrapper.querySelectorAll('.productConfiguratorModal__lenses');
        if(!lensSliderElems) return;

        lensSliderElems.forEach(lensSliderElem => {
            let slider = new Splide(lensSliderElem, {
                type: 'slide',
                perPage: 3,
                perMove: 1,
                gap: 16,
                arrows: false,
                pagination: true,
                breakpoints: {
                    768: {
                        perPage: 2,
                    },
                    576: {
                        perPage: 1,
                    }
                }
            });
            slider.mount();
        })

        initGroupTabs();
    });

    window.configurator.root.addEventListener('update_view', () => {
        if(window.configurator.current_step === 2){
            const lensesWrappers = window.configurator.root.querySelectorAll('.productConfiguratorModal__lenses');

            if(!lensesWrappers) return;

            const hiddens = window.configurator.root.querySelectorAll('input[type=hidden]');
            lensesWrappers.forEach(lensesWrapper => {
                const lenses = lensesWrapper.querySelectorAll('.productConfiguratorModal__lenses__item');
                const lensError = lensesWrapper.querySelector('.productConfiguratorModal__lenses__notFound');

                const formData = [];
                hiddens.forEach(hidden => {
                    formData.push({
                        key: hidden.name,
                        value: hidden.value
                    });
                });

                if(!lenses || !formData){
                    lensError.classList.add('active');
                    return;
                }
                lenses.forEach(lens => {
                    let config = lens.getAttribute('data-config');
                    let lensType = lens.getAttribute('data-lens-type') ?? "";

                    config = JSON.parse(config) ?? [];
                    lensType = lensType.split(',');

                    lens.style.display = "block";

                    if(!config) return;

                    let hided = 0;
                    formData.forEach(hidden => {
                        if(parseFloat(hidden.value) < parseFloat(config[`${hidden.key}_min`]) || parseFloat(hidden.value) > parseFloat(config[`${hidden.key}_max`])){
                            lens.style.display = "none";
                            hided++;
                        }
                    });

                    let selectedLensType = formData.find(hidden => hidden.key === 'glass_type_value');
                    if((lensType && selectedLensType) && !lensType.includes(selectedLensType.value)){
                        lens.style.display = "none";
                        hided++;
                    }

                    lensError.classList.remove('active');

                    if(lensesWrapper.getAttribute('data-count') <= hided){
                        lensError.classList.add('active');
                    }
                })
            })
        }
    });

    window.configurator.root.addEventListener('go-back', () => {
        if(window.configurator.current_step === 2){
            let lensRadio = window.configurator.root.querySelectorAll('input[type=radio][name=lens]');
            lensRadio.forEach(radio => {radio.checked = false;})
            let hidden = window.configurator.root.querySelector('input[type=hidden][name=lens_value]');
            hidden.value = "";
        }
    })

    const forwardBtn = step2Wrapper.querySelector('.go-forward');
    if(forwardBtn){
        forwardBtn.addEventListener('click', () => {
            const errorMsg = step2Wrapper.querySelector('.product-configurator-error');
            let hidden = window.configurator.root.querySelector('input[type=hidden][name=lens_value]');
            let pass = false;
            if(hidden.value) pass = true;

            if(pass){
                errorMsg.classList.remove('active');
                window.configurator.current_step++;
                window.configurator.update();
            } else{
                errorMsg.classList.add('active');
            }
        });
    }
}

const initGroupTabs = () => {
    const form = window.configurator.root.querySelector('form');
    if(!form) return;

    form.addEventListener('change', changeTab)

    changeTab();
}

const changeTab = () => {
    const currentTab = window.configurator.root.querySelector('input[type=hidden][name=lens_group_value]');
    const tabs = window.configurator.root.querySelectorAll('.productConfiguratorModal__lenses[data-target]');

    if(!tabs || !currentTab) return;

    tabs.forEach(tab => {
        tab.style.display = "none";

        if(tab.getAttribute('data-target') === currentTab.value){
            tab.style.display = "block";
        }
    })
}