export const initCollapses = () => {
    const collapseBlocks = document.querySelectorAll('.productConfigurationCollapse');

    if (collapseBlocks) {
        collapseBlocks.forEach(collapse => {
            const opener = collapse.querySelector('.productConfigurationCollapse__title');
            const content = collapse.querySelector('.productConfigurationCollapse__content');

            if (opener) {
                opener.addEventListener('click', () => {
                    content.classList.toggle('active');
                    opener.classList.toggle('active');
                    if (content.style.maxHeight) content.style.maxHeight = null;
                    else content.style.maxHeight = `${(content.scrollHeight + 64)}px`;
                })
            }
        })
    }
}