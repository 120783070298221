export const initProductConfiguratorModal = () => {
    const closeBtns = window.configurator.root.querySelectorAll('.close');
    const goBackBtns = window.configurator.root.querySelectorAll('.go-back');

    if(closeBtns){
        closeBtns.forEach(closeBtn => {
            closeBtn.addEventListener('click', () => {
                window.configurator.root.close();
                document.body.classList.remove('productConfigurator--opened');
            });
        })
    }

    if(goBackBtns){
        goBackBtns.forEach(goBackBtn => {
            goBackBtn.addEventListener('click', () => {
                window.configurator.trigger('go-back');
                window.configurator.current_step--;
                window.configurator.update();
            });
        })
    }

    window.configurator.root.addEventListener('update_view', () => {
        const steps = document.querySelectorAll('.productConfiguratorModal__step');

        if(!steps) return;

        steps.forEach(step => {
            step.style.display = "none";
            if(step.getAttribute('data-step') === window.configurator.current_step.toString()){
                step.style.display = "block";
            }
        })
    });

    window.configurator.update();
}