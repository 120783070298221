export const initStep1 = () => {
    const step1Wrapper = window.configurator.root.querySelector('.productConfiguratorModal__step[data-step="1"]');

    if(!step1Wrapper) return;

    const forwardBtn = step1Wrapper.querySelector('.go-forward');
    if(forwardBtn){
        forwardBtn.addEventListener('click', () => {
            const fields_to_check = step1Wrapper.querySelectorAll('select, input[type=radio]:checked');
            let pass = true;
            if(fields_to_check){
                fields_to_check.forEach(field => {
                    field.setAttribute('style', "border: none");
                    let hidden = window.configurator.root.querySelector(`input[type=hidden][name=${field.name}_value]`);
                    if(field.getAttribute('data-required') && field.getAttribute('data-required') === "1" && !hidden.value){
                        field.setAttribute('style', "border: 1px solid red !important");
                        pass = false;
                    }
                })
            }

            if(pass){
                window.configurator.current_step++;
                window.configurator.update();
            }
        });
    }
}